
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import { toDate } from '../utils/common'
import store from '../store'
@Options({
  components: {}
})
export default class Index extends Vue {
  toDate = toDate;
  d = {};
  loading = false;
  created () {
    // 检测路由是否传值
    const token = this.$route.query.token as string
    const authKey = this.$route.query.authKey as string
    const code = this.$route.query.code as string
    if (token) {
      store.dispatch('session/login', {
        token: decodeURIComponent(token),
        authKey: decodeURIComponent(authKey),
        code: code
      })
    }
    this.loading = true
    api
      .getJiBen({ code: (store.state as any).session.code })
      .then((data: any) => {
        this.d = data
        store.dispatch('company/set', {
          company: data.company,
          creditCode: data.credit_code
        })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
